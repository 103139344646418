<template>
  <div>
    <el-input v-model="displayValue" @blur="isInputActive = false" @focus="isInputActive = true" />
  </div>
</template>
<script>
export default {
  name: "Money",
  props: ["value"],
  data: function() {
    return {
      isInputActive: false,
    };
  },
  computed: {
    displayValue: {
      get: function() {
        if (this.isInputActive) {
          return this.value.toString();
        } else {
          if (this.value && typeof this.value == "number") {
            var num = this.value.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1 ");
            return num;
          } else if (this.value && typeof this.value == "string") {
            var num = parseFloat(this.value.split(",").join("."))
              .toFixed(2)
              .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1 ");
            return num;
          } else return this.value;
        }
      },
      set: function(modifiedValue) {
        this.$emit("input", modifiedValue);
      },
    },
  },
};
</script>
