<template>
  <el-dialog
    id="EditContractDialog"
    :title="title"
    :visible.sync="dialogShow"
    center
    modal
    top="5vh"
    :close-on-click-modal="false"
    width="496px"
    close-on-press-escape
    class="my-dialog abow_dialog"
    @opened="onShow"
  >
    <!-- <el-scrollbar wrap-style="max-height: 600px;"> -->
    <el-form ref="EditContractForm" :model="model" :rules="rules" @submit.native.prevent="onSubmit" v-loading="loading">
      <!-- <el-row v-if="loading" justify="center" align="middle" type="flex" style="height:650px">
        <img class="svg" :src="require('../../../assets/icons/no_data.svg')" />
      </el-row> -->
      <el-row v-if="loading" justify="center" align="middle" type="flex" style="height:650px">
        <el-empty :image-size="100" :image="require('../../../assets/icons/no_data.svg')"></el-empty>
      </el-row>
      <el-row v-else>
        <el-form-item prop="name" :label="$t('Назва')">
          <el-input v-model="model.name" ref="nameInput" autocomplete="off" :placeholder="$t('Не обрано')"></el-input>
        </el-form-item>
        <el-form-item prop="customer" :label="$t('Замовник')">
          <el-tooltip placement="right" effect="light">
            <div slot="content">
              {{ customerComputedTooltip }}
            </div>
            <el-input
              v-model="customerComputed"
              autocomplete="off"
              suffix-icon="el-icon-more"
              @mousedown.native="findOrg($event, 'customer')"
              readonly
              :placeholder="$t('Не обрано')"
            ></el-input>
          </el-tooltip>
        </el-form-item>
        <el-row justify="space-between" type="flex">
          <el-col :span="11">
            <el-form-item prop="beginDate" :label="$t('Початок')">
              <el-date-picker type="date" format="dd.MM.yyyy" v-model="model.beginDate" style="width:100%"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item prop="endDate" :label="$t('Кінець')">
              <el-date-picker type="date" format="dd.MM.yyyy" v-model="model.endDate" style="width:100%"></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item prop="executor" :label="$t('Виконавець')">
          <el-tooltip placement="right" effect="light">
            <div slot="content">
              {{ executorComputedTooltip }}
            </div>
            <el-input
              v-model="executorComputed"
              autocomplete="off"
              suffix-icon="el-icon-more"
              @mousedown.native="findOrg($event, 'executor')"
              readonly
              :placeholder="$t('Не обрано')"
            ></el-input>
          </el-tooltip>
        </el-form-item>
        <el-form-item prop="providers" :label="$t('Постачальники')">
          <el-tooltip placement="right" effect="light">
            <div slot="content">
              <div v-for="tt in providersComputedTooltip" :key="tt">{{ tt }}<br /></div>
            </div>
            <el-input
              v-model="providersComputed"
              autocomplete="off"
              suffix-icon="el-icon-more"
              @mousedown.native="findOrg($event, 'providers')"
              readonly
              :placeholder="$t('Не обрано')"
            ></el-input>
          </el-tooltip>
        </el-form-item>
        <el-form-item prop="structures" :label="$t('Структури')">
          <el-tooltip placement="right" effect="light">
            <div slot="content">
              <div v-for="tt in structuresComputedTooltip" :key="tt">{{ tt }}<br /></div>
            </div>
            <el-input
              v-model="structuresComputed"
              autocomplete="off"
              suffix-icon="el-icon-more"
              @mousedown.native="findOrgWithTree($event, 'structures')"
              readonly
              :placeholder="$t('Не обрано')"
            ></el-input>
          </el-tooltip>
        </el-form-item>
        <el-form-item prop="sku" :label="$t('Товари')">
          <el-tooltip placement="right" effect="light">
            <div slot="content">
              <div v-for="tt in skuComputedTooltip" :key="tt">{{ tt }}<br /></div>
            </div>
            <el-input
              v-model="skuComputed"
              autocomplete="off"
              suffix-icon="el-icon-more"
              @mousedown.native="sku($event)"
              readonly
              :placeholder="$t('Не обрано')"
            ></el-input>
          </el-tooltip>
        </el-form-item>
        <el-row justify="space-between" type="flex">
          <el-col :span="11">
            <el-form-item prop="currency" :label="$t('Валюта')">
              <el-select style="width:100%" v-model="model.currency">
                <el-option v-for="item in selectOptions.currency" :key="item.value" :label="$t(item.label)" :value="item.value"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item prop="rate" :label="$t('Курс')">
              <el-input v-model="model.rate" autocomplete="off" :disabled="model.currency == 'UAH'"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="space-between" type="flex">
          <el-col :span="11">
            <el-form-item prop="planValue" :label="$t('Загальний план')">
              <Money :value="model.planValue" v-if="model.planMeasure == 'money'" @input="onPlanValueInput"> </Money>
              <el-input v-model="model.planValue" autocomplete="off" v-if="model.planMeasure !== 'money'"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item prop="planMeasure" :label="$t('Одиниця виміру плану')">
              <el-select style="width:100%" v-model="model.planMeasure">
                <el-option v-for="item in selectOptions.planMeasure" :key="item.value" :label="$t(item.label)" :value="item.value"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item prop="bitrixID" label="Bitrix ID">
          <el-input v-model="model.bitrixID" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item prop="attachFile" :label="$t('Прикріплений файл')">
          <el-tooltip placement="right" effect="light">
            <div slot="content">
              <div v-for="tt in attachFileComputedTooltip" :key="tt">{{ tt }}<br /></div>
            </div>
            <el-input
              v-model="attachFileComputed"
              autocomplete="off"
              suffix-icon="el-icon-more"
              @mousedown.native="attach($event)"
              readonly
              :placeholder="$t('Не обрано')"
            ></el-input>
          </el-tooltip>
        </el-form-item>
        <el-form-item prop="notes" :label="$t('Примітка')">
          <el-input type="textarea" autosize v-model="model.notes" autocomplete="off"></el-input>
        </el-form-item>
      </el-row>
    </el-form>
    <!-- </el-scrollbar> -->

    <span slot="footer" class="dialog-footer">
      <el-button native-type="submit" type="success" @click="onCommit" class="apply-button">{{ $t("Застосувати") }}</el-button>
      <el-button type="text" @click="dialogShow = false" class="cancel-button">{{ $t("Відмінити") }}</el-button>
    </span>
    <FindOrg @find-commit="onFindCommit" bus-event="forms.orgs.find" />
    <FindOrgWithTree @find-commit="onFindCommit" />
    <SKUDictionary @find-commit="onFindCommit" />
    <AttachFile @attach-commit="onAttachCommit" bus-event="forms.attach.file" />
  </el-dialog>
</template>

<script>
import { bus } from "@/main";
import { _ } from "vue-underscore";
import Money from "@/components/Money";

export default {
  name: "EditContract",
  components: {
    Money,
    FindOrg: () => import("@/components/forms/FindOrg"),
    FindOrgWithTree: () => import("@/components/forms/FindOrgWithTree"),
    SKUDictionary: () => import("@/components/forms/SKUDictionary"),
    AttachFile: () => import("@/components/forms/AttachFile"),
  },
  data() {
    var that = this;
    var defaultValidateRule = { required: true, message: $tt("Це поле є необхідним") };
    var checkBeginDate = (rule, value, callback) => {
      if (value && that.model.endDate) {
        if (new Date(value) > new Date(that.model.endDate)) callback(new Error($tt("Дата початку пізніше дати кінця")));
      }
      callback();
    };
    var checkEndDate = (rule, value, callback) => {
      if (value && that.model.beginDate) {
        if (new Date(value) < new Date(that.model.beginDate)) callback(new Error($tt("Дата кінця раніше дати початку")));
      }
      callback();
    };
    var checkArray = (rule, value, callback) => {
      if (value && !Array.isArray(value)) {
        callback(new Error($tt("Невірний формат даних!")));
      }
      callback();
    };
    var digitalValueCurrencyValidateRule = (rule, value, callback) => {
      if (value && that.model.currency != "UAH") {
        if ((!that.isNumeric(value) && !that.isNumeric(value.split(",").join("."))) || value < 0) {
          callback(new Error($tt("Поле має бути невід'ємним числом")));
        } else callback();
      } else if (!value && that.model.currency != "UAH") {
        callback(new Error($tt("Це поле є необхідним")));
      } else callback();
    };
    var digitalValueValidateRule = (rule, value, callback) => {
      if (value == undefined || value == "") {
        callback();
      } else if ((!that.isNumeric(value) && !that.isNumeric(value.split(",").join("."))) || value < 0) {
        callback(new Error($tt("Поле має бути невід'ємним числом")));
      } else {
        callback();
      }
    };
    return {
      loading: false,
      dialogShow: false,
      title: $tt("Створити новий"),
      operation: null,
      model: {},
      initModel: {},
      rules: {
        name: [defaultValidateRule],
        //customer: [defaultValidateRule],
        executor: [defaultValidateRule],
        providers: [
          {
            validator: checkArray,
          },
        ],
        // structures: [
        //   defaultValidateRule,
        //   {
        //     validator: checkArray,
        //   },
        // ],
        currency: [defaultValidateRule],
        beginDate: [
          defaultValidateRule,
          {
            validator: checkBeginDate,
          },
        ],
        endDate: [
          defaultValidateRule,
          {
            validator: checkEndDate,
          },
        ],
        rate: [{ validator: digitalValueCurrencyValidateRule }],
        planValue: [{ validator: digitalValueValidateRule }],
        //bitrixID: [defaultValidateRule], //!!
      },
    };
  },

  methods: {
    onPlanValueInput(value) {
      this.model.planValue = value;
    },
    findOrg(event, id) {
      switch (id) {
        case "customer": {
          bus.$emit("forms.orgs.find.show", { target: "customer", title: $tt("Пошук організації замовника"), singleRecord: true });
          break;
        }
        case "executor": {
          bus.$emit("forms.orgs.find.show", { target: "executor", title: $tt("Пошук організації виконавця"), singleRecord: true });
          break;
        }
        case "providers": {
          bus.$emit("forms.orgs.find.show", {
            target: "providers",
            title: $tt("Пошук організації постачальників"),
            singleRecord: false,
            selectedOrgs: this.model.providers,
            allowEmpty: true,
          });
          break;
        }
        default:
          break;
      }
    },
    findOrgWithTree(event, id) {
      bus.$emit("forms.orgsWithTree.find.show", {
        target: "structures",
        title: $tt("Пошук структур"),
        selectedOrgs: this.model.structures,
      });
    },
    sku(event) {
      bus.$emit("forms.sku.dictionary.show", {
        target: "sku",
        title: $tt("Редагування довідника товарів"),
        selectedSKU: this.model.sku,
      });
    },
    attach(event) {
      bus.$emit("forms.attach.file.show", {
        title: $tt("Прикріплений файл"),
        initModel: { attach: this.model.attachFile, contractId: this.model._id },
      });
    },
    onFindCommit(data) {
      switch (data.target) {
        case "customer": {
          this.customerComputed = data.result[0];
          break;
        }
        case "executor": {
          this.executorComputed = data.result[0];
          break;
        }
        case "providers": {
          this.providersComputed = data.result;
          break;
        }
        case "structures": {
          this.structuresComputed = data.result;
          break;
        }
        case "sku": {
          this.$set(this.model, "sku", data.result);
          break;
        }
        default:
          break;
      }
    },
    onAttachCommit(data) {
      this.$set(this.model, "attachFile", data && data.id ? data : null);
    },
    onCommit() {
      this.$refs.EditContractForm.validate((valid) => {
        if (valid) {
          this.$emit("commit", { operation: this.operation, model: this.prepareForCommit(this.model) });
        } else {
          this.$message.error($tt("Перевірте введені дані!"));
        }
        return false;
      });
    },
    onShow() {
      this.$refs.EditContractForm.resetFields();
      if (this.operation != "create" /*&& !this.initModel.fullLoaded*/) {
        this.$store.dispatch("contracts/loadOne", { id: this.initModel._id, silentLoad: true }).then((data) => {
          this.initModel = data.data.data;
          this.model = _.extend({}, this.initModel);
          this.$nextTick(() => {
            this.loading = false;
            this.$nextTick(() => {
              this.$refs.nameInput.focus();
            });
          });
        });
      } else {
        this.model = _.extend({}, this.initModel);
        this.$nextTick(() => {
          this.loading = false;
          this.$nextTick(() => {
            this.$refs.nameInput.focus();
          });
        });
      }
    },
    prepareForCommit(data) {
      if (data.currency == "UAH") data.rate = "";
      else data.rate = data.rate && !this.isNumeric(data.rate) ? data.rate.split(",").join(".") : data.rate;
      data.planValue =
        data.planValue && !this.isNumeric(data.planValue)
          ? data.planValue
              .split(",")
              .join(".")
              .split(" ")
              .join("")
          : data.planValue;
      return data;
    },
  },
  created() {
    bus.$on("forms.contracts.edit.show", (data) => {
      this.loading = true;
      this.title = (data && data.title) || this.title;
      this.operation = (data && data.operation) || "create";
      this.initModel = _.extend({}, data ? data.initModel : {});
      this.dialogShow = true;
    });
    bus.$on("forms.contracts.edit.hide", () => {
      this.dialogShow = false;
    });
  },
  computed: {
    selectOptions() {
      return this.$store.getters["main/selectionOptions"].contracts;
    },
    customerComputed: {
      get() {
        if (!this.model.customer) return null;
        else return this.model.customer.name;
      },
      set(value) {
        this.model.customer = value;
      },
    },
    executorComputed: {
      get() {
        if (!this.model.executor) return null;
        else return this.model.executor.name;
      },
      set(value) {
        this.model.executor = value;
      },
    },
    providersComputed: {
      get() {
        if (!this.model.providers) return null;
        else {
          if (this.model.providers.length > 1) return `${this.model.providers[0].name} ` + $tt("(+ще") + ` ${this.model.providers.length - 1})`;
          else if (this.model.providers.length == 1) return `${this.model.providers[0].name}`;
          else return null;
        }
      },
      set(value) {
        this.model.providers = value;
      },
    },
    structuresComputed: {
      get() {
        if (!this.model.structures) return null;
        else {
          if (this.model.structures.length > 1) return `${this.model.structures[0].name} ` + $tt("(+ще") + ` ${this.model.structures.length - 1})`;
          else if (this.model.structures.length == 1) return `${this.model.structures[0].name}`;
          else return null;
        }
      },
      set(value) {
        this.model.structures = value;
      },
    },
    skuComputed: {
      get() {
        if (!this.model.sku) return null;
        else {
          if (this.model.sku.length > 1) return `${this.model.sku[0].sku} ` + $tt("(+ще") + ` ${this.model.sku.length - 1})`;
          else if (this.model.sku.length == 1) return `${this.model.sku[0].sku}`;
          else return null;
        }
      },
      set(value) {
        this.model.sku = value;
      },
    },
    attachFileComputed: {
      get() {
        if (!this.model.attachFile) return null;
        else {
          return this.model.attachFile.srcName;
        }
      },
      set(value) {
        this.model.attachFile = value;
      },
    },
    attachFileComputedTooltip() {
      if (!this.model.attachFile) return [$tt("Не обрано")];
      else {
        return [
          `${$tt("Назва")}: ${this.model.attachFile.srcName}`,
          `${$tt("Розмір")}: ${this.bytesToSize(this.model.attachFile.size)}`,
          `${$tt("Завантажено")}: ${this.timestampToLocaleString(this.model.attachFile.uploaded)}`,
        ];
      }
    },
    customerComputedTooltip() {
      if (!this.model.customer) return $tt("Не обрано");
      else {
        return this.model.customer.name;
      }
    },
    executorComputedTooltip() {
      if (!this.model.executor) return $tt("Не обрано");
      else {
        return this.model.executor.name;
      }
    },
    providersComputedTooltip() {
      if (!this.model.providers || this.model.providers.length == 0) return [$tt("Не обрано")];
      else {
        var more = this.model.providers.length > 10 ? [" " + $tt("(+ще") + " " + (this.model.providers.length - 10) + ")"] : [];
        var result = this.model.providers.slice(0, 9).map((item) => {
          return item.name;
        });
        return result.concat(more);
      }
    },
    structuresComputedTooltip() {
      if (!this.model.structures || !Array.isArray(this.model.structures) || this.model.structures.length == 0) return [$tt("Не обрано")];
      else {
        var more = this.model.structures.length > 10 ? [" " + $tt("(+ще") + " " + (this.model.structures.length - 10) + ")"] : [];
        var result = this.model.structures.slice(0, 9).map((item) => {
          return item.name;
        });
        return result.concat(more);
      }
    },
    skuComputedTooltip() {
      if (!this.model.sku || !Array.isArray(this.model.sku) || this.model.sku.length == 0) return [$tt("Не обрано")];
      else {
        var more = this.model.sku.length > 10 ? [" " + $tt("(+ще") + " " + (this.model.sku.length - 10) + ")"] : [];
        var result = this.model.sku.slice(0, 9).map((item) => {
          return item.sku;
        });
        return result.concat(more);
      }
    },
    planValueComp() {
      var number = numeral(this.model.planValue);
      return number.format("0,00");
    },
  },
  watch: {
    "model.currency": function(oldValue, newValue) {
      if (oldValue != newValue)
        this.$refs.EditContractForm.validate((valid) => {
          return false;
        });
    },
    "model.planMeasure": function(oldValue, newValue) {
      if (oldValue != newValue && !this.model.planValue) {
        this.model.planValue = 0;
      }
    },
  },
};
</script>
<style lang="scss">
#EditContractDialog {
  .el-dialog {
    max-height: 730px !important;
  }
}
</style>
